import React from "react"
import { 
    // Link,
    graphql 
} from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

class SangNamGan extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="SangNamGan" />
        <div style={{ position: 'relative', padding: '6rem 1.2rem 4rem', minHeight: '350px', }}>
          <Image 
            style={{ opacity: '.4', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,}}
            fluid={data.backgroundImage.childImageSharp.fluid} 
          />
          <div style={{ maxWidth: '1000px', textAlign: 'center', margin: '0 auto', position: 'relative'}}>
            <Title>SangNamGan: Reimagining Community Development in Lao PDR</Title>
            <div style={{ maxWidth: '700px', margin: '0 auto'}}>
              <span style={{fontWeight: '600', }}>Giving villagers a voice in local development initiatives</span>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#F9F6F7', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto'}}>
            <SectionHeading>Project Context</SectionHeading>
            <div>
              <p>
                In 2016, while working in northern Lao PDR, I discovered a school director and English teacher of Nam Meung Town desperately wanted a secondary school library. From their view, the question was simple: <Accent className="bold">no funding and no way to get funding</Accent>.
              </p>
              <p>
                After initial efforts to apply for funding on the community's behalf was unsuccessful, I took on this project personally. I performed qualitative research within the community to formulate a way to fund the library. I conducted extensive ethnographic work, carried out contextual inquires with NGO workers, community leaders and townspeople, and led a focus group for feedback.
              </p>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#fff', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto'}}>
            <SectionHeading style={{ marginBottom: '20px'}}>The Problem: So Many Needs, Medium-Sized Villages Not Prioritized</SectionHeading>
            <p>
              I emailed more than 10 education-focused NGOs to solicit their help, but each has its own reason for declining to help—understaffed, lack of funds, outside of their pre-determined program
              activities. While the current aid system was certainly helping many villages in need, there were still many more villages that were not being served. I noticed a gap in the market, a design opportunity.
            </p>
            <p>
              I began generative research with the following questions in mind:
            </p>
            <p style={{color: '#f9a83d', fontWeight: 600, borderLeft: '3px solid', paddingLeft: '20px', marginLeft: '20px'}}>
How might we enable this community to build a library? More broadly, how might we design a model that empowers communities to garner investments for projects?</p>
            <SectionSubheading style={{ margin: '40px 0 10px'}}>Additional Constraints</SectionSubheading>
            <div>
                <p>
                  <ul style={{ marginTop: '5px', paddingLeft: '15px', }}>
                      <li><Accent className=" bold">No Handouts</Accent> - There would not be a "knight in shining armor" complex within the model.</li>
                      <li><Accent className=" bold">Inclusion and Empowerment</Accent> - The community would be involved from the onset and empowered throughout the process.</li>
                  </ul>
                </p>
            </div>
            <SectionSubheading style={{ margin: '40px 0 10px'}}>My Role</SectionSubheading>
            <p>
              I led the <Accent className="color bold">generative research and ideation phases</Accent> of SangNamGan between August 2016 and October 2016.
              Once an approach was proposed, I continued working on the project during the implementation phase from October 2016 to October 2017.
            </p>
          </div>
        </div>
        <div style={{ backgroundColor: '#F9F6F7', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto', }}>
            <SectionHeading style={{ marginBottom: '10px',}}>Discovery Phase: $$ Follow the Money $$</SectionHeading>
            <p>
              I formulated research questions to investigate the two widely held beliefs of <Accent className="bold">lack of funds</Accent> and <Accent className="bold">lack of agency</Accent>. My research questions included:
              <ul style={{ margin: '.5rem 0 0 2rem', listStyle: 'none'}}>
                <li><Accent className="bold">Question 1:</Accent> How do people spend their money?</li>
                <li><Accent className="bold">Question 2: </Accent> What are the most prioritized spending categories?</li>
                <li><Accent className="bold">Question 3: </Accent> What are the miscellanous categories? Do people have money to "treat" themselves?</li>
              </ul>
            </p>
            <SectionSubheading style={{ margin: '40px 0 10px'}}>Ethnographic Field Research</SectionSubheading>
              <p style={{marginBottom: 0}}>
                I immersed myself in Nam Meung village for four weeks, living, working and volunteering in the town. I became friendly with villagers through passive and active participation, including celebrating holidays and attending community events, joining family dinners with friends,
                helping with weekend chores like rice farming and bamboo collecting, and teaching English twice a week for secondary and primary school children. 
              </p>
              </div>
          </div>
          <div style={{ backgroundColor: 'rgb(255, 195, 88)', padding: '4rem 1.2rem',}}>
              <div style={{ maxWidth: '780px', margin: '0 auto', }}>
              <SectionSubheading style={{ margin: '0 0 10px'}}>Key Findings</SectionSubheading>

                <p>
                    I observed spending habits and money flow in great detail:
                </p>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 auto', flexWrap: 'wrap'}}>
                  <div style={{ flex: '0 0 48%', marginBottom: '30px'}}>
                    <Accent className="bold" style={{display: 'block'}}>Rotating savings accounts outside of banks</Accent> A group of 10 people would pool their money in a 10-month period, with each person assigned a set month to receive the pool. The person who received the first month's pooled money lost in interset, but had the first access to a sum of money.
                  </div>
                  <div style={{ flex: '0 0 48%'}}>
                    <Accent className="bold" style={{display: 'block'}}>Lottery Tickets Worth the Risk</Accent> People were often buying lottery tickets, gambling on a quick win. Three days of the week, lotto sellers set up their tables to sell numbers and there were regular customers.
                  </div>
                  <div style={{ flex: '0 0 48%'}}>
                    <Accent className="bold" style={{display: 'block'}}>Events Galore</Accent> Baby showers, weddings, and small community gatherings were all part of the daily life. At these types of parties, a contribution of 35,000LAK was common.
                 </div>
              </div>
              </div>
            </div>
        <div style={{ backgroundColor: '#FFF', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto', }}>
            <SectionHeading>Synthesis & Proposed Solution</SectionHeading>
            <p>
              My research had unconvered a few insights:
              <ul>
                <li style={{ margin: '5px auto'}}>
                  <Accent className="bold">Disposable Income:</Accent> Some members of the community had disposable income that could be directed towards the implementation of a community project - the issue was how to motivate users to invest in long-term projects over short-term needs. 
                </li>
                <li style={{ margin: '5px auto'}}>
                  <Accent className="bold">Multiple Personas:</Accent> Multiple user personas existed within the community. Some households were doing well for themselves, with side hustles and regular savings.  
                </li>
              </ul>
            </p>
            <SectionSubheading style={{ margin: '30px 0 10px'}}>A 50/50 Model: Increasing Motivation Through Loss Aversion</SectionSubheading>
            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', margin: '20px 0 20px 0'}}>
              <div style={{ marginRight: '20px', flex: '0 0 100px'}}>
                <Image style={{ margin: '0 auto'}} fluid={data.moneyloss.childImageSharp.fluid} />
              </div>
              <LossIconContainer>
                <p style={{ marginBottom: 0}}>A reoccuring theme was that people were not interested in investing without a clear return. To combat this feeling, we tapped into the loss aversion theory.                       Loss aversion suggests that for individuals the pain of losing is psychologically twice as powerful as the pleasure of gaining.</p>
              </LossIconContainer>
            </div>
            <p style={{ marginBottom: 0}}>We <Accent className="color bold">offered to cover half of the needed funding through non-profits and foundations</Accent> if the community came up with the other half.</p>
          </div>
        </div>  
        <div style={{ backgroundColor: '#F9F6F7', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto', }}>
          <SectionHeading>Concept Testing</SectionHeading>
            <SectionSubheading style={{ margin: '30px 0 10px'}}>Parent Focus Group: <span style={{ fontWeight: 400, fontStyle: 'italic' }}>How do villagers respond to the idea of funding 50% of projects?</span></SectionSubheading>
            <p>I led a focus group of mothers to determine what they thought of communities funding 50% of a project.
              Would 75,000LAK (~$9.25) be a feasible contribution for each of the 200
              households in Nam Meung Village and Meng Town? The responses were
              revealing.
            </p>
            <StyledImage style={{ flex: '0 0 80%'}} fluid={data.parentgroup.childImageSharp.fluid} />
            <p style={{ margin: '20px 0 0'}}>The women I led the focus group with were on-board, but concerned about villager who rely on subsistence farming. They did not believe those families would be able to pay such a contribution. I considered alterantive plans in the event that community members could not financially contribute. Could they volunteer their time? Lumber? Other materials instead? This was a concern I noted down and brought back to my community partners.</p>
            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', maxWidth: '790px', margin: '0 auto'}}> 
          </div>
          </div>
        </div> 
        <div style={{ backgroundColor: '#FFF', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto'}}>
            <SectionHeading>Outcomes</SectionHeading>
            <p>
              The 50/50 model was proposed to multiple non-profits and foundations and <StyledLink target="_blank" rel="noopener noreferrer"  href="https://jailao.org">Jai Lao Foundation</StyledLink> agreed to fund a 12-month pilot. The pilot launched in August 2017 and ended in October 2018.
            </p>
            <SectionSubheading style={{ margin: '30px 0 10px'}}>Measurements of Success</SectionSubheading>
            <p>
                The secondary school library was the first project under SangNamGan. Word spread and soon other community leaders began reaching out about potential projects. Future iterations of the concept continued to flourish - with adjustments made to allow communities to contribute labor and materials in replace of a monetary match.
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

const Title = styled.h1`
  font-size: 2.4rem;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    font-size: 3rem;
  }
`;

const SectionHeading = styled.h2`
  text-transform: none;
  font-size: 1.4rem;
  text-align: left;

  @media (min-width: 769px) {
    font-size: 1.6rem;
  }
`;

const SectionSubheading = styled.h3`
  margin-top: 70px;
  line-height: 1.3;
`

const Accent = styled.span` 
  &.bold {
    font-weight: 600;
  }

  &.color {
    color: #f9a83d;
  }

  &.break {
    word-break: break-all;
  }

  &.italic {
    font-style: italic;
  }
  &.underline {
    text-decoration: underline;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  margin: 1.2rem auto; 

  @media (min-width: 769px) {
     width: 80%;
  }
`;

const LossIconContainer = styled.div`
  flex: 0 0 100%;
  text-align: left;
  margin: 15px 0 0 0;

  @media (min-width: 769px) {
    flex-basis: calc(100% - 150px);
    margin: 0 0 0 30px;
  }
`;

const StyledLink = styled.a`
  font-weight: 600;
  box-shadow: none;
  text-decoration: none;
  color: #000;
  border-bottom: 3px solid #000;

  :hover {
    border-bottom: 3px solid #f9a83d;
    color: #f9a83d;
  }
`;

export default SangNamGan

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    backgroundImage: file(absolutePath: { regex: "/sangnamgan-outsideschool.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bambooHut: file(absolutePath: { regex: "/sangnamgan-bambooHut.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    circle: file(absolutePath: { regex: "/circle.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mengschool: file(absolutePath: { regex: "/sangnamgan-school.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HHstudents: file(absolutePath: { regex: "/sangnamgan-students.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    villagetownhall: file(absolutePath: { regex: "/sangnamgan-process-hats.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    underroof: file(absolutePath: { regex: "/sangnamgan-underroof.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kidsinclassroom: file(absolutePath: { regex: "/sangnamgan-process-kids.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    parentgroup: file(absolutePath: { regex: "/sangnamgan-process.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    childrenbikes: file(absolutePath: { regex: "/sangnamgan-bikes.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    studentsonsteps: file(absolutePath: { regex: "/sangnamgan-students.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smallkidshandsup: file(absolutePath: { regex: "/sangnamgan-school-hands.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ethnoparents: file(absolutePath: { regex: "/APPR_First_parents.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    confusion: file(absolutePath: { regex: "/confusion.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nomoney: file(absolutePath: { regex: "/no-money.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    moneyloss: file(absolutePath: { regex: "/moneyloss.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stress: file(absolutePath: { regex: "/stress.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landscapeanalysis: file(absolutePath: { regex: "/CompAnalysis.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timeline: file(absolutePath: { regex: "/Timeline3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`